html,
body {
    background: url(background.jpg);
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

html,
div#root,
html,
body {
    height: 100%;
}

.narrow {
    max-width: 30pt;
}

div.container {
    position: relative;
    width: 500px;
    margin: auto;
    background-color: #fff;
    border-radius: 15pt;
    border: 2px solid #ff808b;
    top: 10pt;
    padding: 10pt;
    padding-left: 20pt;
    padding-right: 20pt;
    margin-bottom: 20pt;
    padding-bottom: 20pt;
    justify-content: space-between;
    flex-direction: column;
    /* min-height: calc(100vh - 45pt); */
    display: flex;
}

div.form {
    flex-grow: 1;
    width: 100%;
}

div.buttons {
    width: 100%;
    height: 30pt;
    justify-content: flex-end;
    display: flex;
}

div.times2 {
    padding-top: 11pt;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 768px) {
    div.container {
        /* min-height: calc(100vh - 50pt); */
        margin-left: 5pt;
        margin-right: 5pt;
        width: auto;
        max-width: auto;
        padding-left: 10pt;
        padding-right: 10pt;
    }

    .narrow2 {
        max-width: 100pt;
    }

    div.times2 {
        padding-top: 10pt;
    }
}

div.subtypes {
    margin-left: 30pt;
}

p {
    line-height: 1.5;
}
